export const openRegistrationRequiredAlert = () => {
    const dialog = document.querySelector<HTMLDialogElement>('#alert-register');
    if (dialog) {
        dialog.open = true;
    }
};

export const openPremiumRequiredAlert = () => {
    const dialog = document.querySelector<HTMLDialogElement>('#alert-premium');
    if (dialog) {
        dialog.open = true;
    }
};

export const openCountsRequiredAlert = () => {
    const dialog = document.querySelector<HTMLDialogElement>('#alert-counts');
        if (dialog) {
            dialog.open = true;
        }
    };

export const openPremiumRequiredRemovedAlert = () => {
    const dialog = document.querySelector<HTMLDialogElement>('#alert-premium-removed');
    if (dialog) {
        dialog.open = true;
    }
};
