export const plural = (cases: string[], value: number) => {
    let original = value;
    let result, i;
    value = value % 100;
    if (value >= 11 && value <= 19) {
        result = cases[2];
    } else {
        i = value % 10;
        switch (i) {
            case 1:
                result = cases[0];
                break;
            case 2:
            case 3:
            case 4:
                result = cases[1];
                break;
            default:
                result = cases[2];
        }
    }
    return result.replace('{value}', original.toString());
};
