import { LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { property } from 'lit/decorators/property.js';

declare global {
    interface HTMLElementTagNameMap {
        'x-monitoring-controller': MonitoringController;
    }
}

@customElement('x-monitoring-controller')
export class MonitoringController extends LitElement {
    @property()
    audio: string = '';

    @property({ attribute: 'max-items', type: Number })
    maxItems: number = 48;

    protected override firstUpdated() {
        document.addEventListener('bell', () => {
            document.querySelector<HTMLAudioElement>(this.audio)!.play();
        });
        document.addEventListener('monitoring-updated', () => {
            document.querySelector('#empty-notification')!.remove();
        });
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
