import { BodyType, CarCondition, Color, DriveType, EngineType, Period, Transmission, SourceName } from './types';

export const SearchEvents = {
    SET_CRITERIA: 'setcriteria',
    SEARCH: 'searchadverts',
    VIEW_PREFERENCE: 'viewpreference',
};

export type SearchCriteria = {
    city: number;
    region: number;
    make: number;
    model: number;
    generation: number;
    year_to: number;
    year_from: number;
    exchange: boolean;
    price_from: number;
    price_to: number;
    volume_from: number;
    volume_to: number;
    benefit_from: number;
    benefit_to: number;
    mileage_from: number;
    mileage_to: number;
    carrier_code: string[];
    exclude_text: string;
    include_text: string;
    only_private: boolean;
    only_company: boolean;
    with_deleted: boolean;
    color: Color;
    period: Period;
    body_type: BodyType[];
    drive_type: DriveType[];
    transmission: Transmission[];
    engine_type: EngineType[];
    car_condition: CarCondition;
    source_name: SourceName[];
};

export function setViewPreference(value: 'cards' | 'table') {
    document.cookie = `view_preference=${value};path=/;max-age=31536000`;
    document.dispatchEvent(new CustomEvent(SearchEvents.VIEW_PREFERENCE, { detail: value, bubbles: true }));
}

export function search() {
    document.dispatchEvent(new CustomEvent(SearchEvents.SEARCH, { bubbles: true }));
}
