import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { property } from 'lit/decorators/property.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';

declare global {
    interface HTMLElementTagNameMap {
        'x-gallery': FotoramaElement;
    }

    interface JQuery {
        fotorama: () => any;
    }
}

@customElement('x-gallery')
export class FotoramaElement extends LitElement {
    @property({ converter: (value) => (value || '').split(' ') })
    photos: string[] = [];

    sliderRef: Ref<HTMLDivElement> = createRef();

    protected override firstUpdated() {
        $(this.sliderRef).fotorama();
    }

    protected override render() {
        const images = this.photos.map(
            (photo) =>
                html`<a href="${photo}">
                    <img src="${photo}" loading="lazy" alt="" @error="imageErrors += 1" />
                </a>`,
        );

        return html` <div class="gallery" style="max-width: 885px">
            <div
                ${ref(this.sliderRef)}
                class="fotorama"
                data-nav="thumbs"
                data-allowfullscreen="true"
                data-width="100%"
                data-ratio="800/600"
            >
                ${images}
            </div>
        </div>`;
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
