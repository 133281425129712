import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { TinySliderInstance, tns } from 'tiny-slider/src/tiny-slider';
import { property } from 'lit/decorators/property.js';
import { createRef, ref, Ref } from 'lit/directives/ref.js';

declare global {
    interface HTMLElementTagNameMap {
        'x-slider': SliderElement;
    }
}

@customElement('x-slider')
export class SliderElement extends LitElement {
    @property()
    placeholder: string = '';

    @property({ converter: (value) => (value || '').split(' ') })
    photos: string[] = [];

    sliderRef: Ref<HTMLDivElement> = createRef();

    private _sliderInstance!: TinySliderInstance;

    protected override firstUpdated() {
        this._sliderInstance = tns({
            container: this.sliderRef.value,
            items: 1,
            controls: false,
            nav: true,
            navPosition: 'bottom',
            swipeAngle: 30,
        });
    }

    onImageLoadError(e: Event) {
        (e.currentTarget as HTMLImageElement).src = this.placeholder;
    }

    override disconnectedCallback() {
        this._sliderInstance.destroy();
        super.disconnectedCallback();
    }

    protected override render(): unknown {
        let urls = this.photos.length ? this.photos : [this.placeholder];

        const photos = urls.map(
            (value) =>
                html` <div>
                    <img src="${value}" loading="lazy" alt="" @error="${this.onImageLoadError}" />
                </div>`,
        );

        return html` <div class="slider ${this.className}">
            <div ${ref(this.sliderRef)}>${photos}</div>
        </div>`;
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
