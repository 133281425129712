import { LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { query } from 'lit/decorators/query.js';
import imask from 'imask';

declare global {
    interface HTMLElementTagNameMap {
        'x-number-decorator': NumberInputDecoratorElement;
    }
}

@customElement('x-number-decorator')
export class NumberInputDecoratorElement extends LitElement {
    @query('input')
    el!: HTMLInputElement;

    private mask: imask.InputMask<any> | undefined;

    protected override firstUpdated() {
        this.mask = imask(this.el, {
            mask: Number,
            thousandsSeparator: ' ',
        });
    }

    override disconnectedCallback() {
        this.mask!.destroy();
        super.disconnectedCallback();
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
