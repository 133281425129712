import { html, LitElement, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { property } from 'lit/decorators/property.js';
import { state } from 'lit/decorators/state.js';
import { classMap } from 'lit/directives/class-map.js';

declare global {
    interface HTMLElementTagNameMap {
        'x-brazzers': BrazzersElement;
    }
}

@customElement('x-brazzers')
export class BrazzersElement extends LitElement {
    @property()
    placeholder: string = '';

    @property({ converter: (value) => (value || '').split(' ') })
    photos: string[] = [];

    @state()
    index: number = 0;

    onImageLoadError(e: Event) {
        (e.currentTarget as HTMLImageElement).src = this.placeholder;
    }

    override firstUpdated() {
        this.bindControls();
    }

    bindControls() {
        this.querySelectorAll('.brazzers-controls > div').forEach((el, index) => {
            el.addEventListener('mouseenter', () => (this.index = index));
        });
    }

    protected override render(): TemplateResult {
        const images = this.photos.map((value, index) => {
            if (index == this.index) {
                return html`<img src="${value}" loading="lazy" @error="${this.onImageLoadError}" />`;
            }
            return null;
        });

        const controls = this.photos.map(
            (_, index) => html` <div class="${classMap({ active: index == this.index })}"></div> `,
        );

        return html` <div class="brazzers ${this.className}">
            <div class="brazzers-controls">${controls}</div>
            <div class="brazzers-images">${images}</div>
        </div>`;
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
