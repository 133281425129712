import { Notyf } from 'notyf';

const TOAST_EVENT = 'toast';
type ToastType = 'success' | 'error';
type ToastOptions = {
    message: string;
    category: ToastType;
};
export const toast = new Notyf({
    position: { x: 'center', y: 'bottom' },
    ripple: false,
    duration: 3000,
    dismissible: true,
});

function onToast(e: Event | CustomEvent<ToastOptions>) {
    if (e instanceof CustomEvent) {
        console.log(toast);
        toast.open({ type: e.detail.category || 'success', message: e.detail.message });
    }
}

document.addEventListener(TOAST_EVENT, onToast);
window.toast = toast;

declare global {
    interface Window {
        toast: Notyf;
    }
}
