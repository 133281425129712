import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { SearchCriteria, SearchEvents } from '../search';
import { query } from 'lit/decorators/query.js';
import { property } from 'lit/decorators/property.js';
import { state } from 'lit/decorators/state.js';
import { plural } from '../helpers';

declare global {
    interface HTMLElementTagNameMap {
        'x-search-form-controller': SearchFormControllerElement;
        'x-search-hidden-counter': HiddenInputCounterElement;
    }
}

type CriteriaKeys = keyof SearchCriteria;
type CriteriaValues = SearchCriteria[CriteriaKeys];

const CHECKBOX_FIELDS = [
    'drive_type',
    'body_type',
    'transmission',
    'engine_type',
    'car_condition',
    'exchange',
    'only_company',
    'only_private',
    'with_deleted',
    'source_name',
];

@customElement('x-search-form-controller')
export class SearchFormControllerElement extends LitElement {
    @query('form')
    form!: HTMLFormElement;

    @property({ attribute: 'selected-model' })
    selectedModelEl: string = '';

    @property({ attribute: 'selected-generation' })
    selectedGenerationEl: string = '';

    @property({ attribute: 'selected-city' })
    selectedCityEl: string = '';

    protected override firstUpdated() {
        document.addEventListener(SearchEvents.SET_CRITERIA, (e) => {
            this.onSearchCriteriaChanged(e as CustomEvent);
        });
        this.form.addEventListener('reset', () => {
            setTimeout(() => {
                this.resetSearchForm();
                setTimeout(() => window.autobot.search.search(), 30);
            }, 20);
        });
    }

    protected onSearchCriteriaChanged(e: CustomEvent<SearchCriteria>) {
        this.resetSearchForm();

        document.querySelector<HTMLInputElement>(this.selectedModelEl)!.value = e.detail.model?.toString() || '';
        document.querySelector<HTMLInputElement>(this.selectedGenerationEl)!.value = e.detail.generation?.toString() || '';
        document.querySelector<HTMLInputElement>(this.selectedCityEl)!.value = e.detail.city?.toString() || '';

        Object.entries(e.detail).forEach(([name, value]) => {
            console.log(name, value);
            const el = this.form.querySelector<HTMLInputElement>(`[name="${name}"]`);
            if (!el) {
                console.error(`Invalid search form element: ${name}`);
                return;
            }
            this.setElementValue(el, name as CriteriaKeys, value);
        });

        window.autobot.search.search();
    }

    protected resetSearchForm() {
        this.form.querySelectorAll('select').forEach((el) => {
            const selectedOption = el.querySelector<HTMLOptionElement>('option[selected]');
            if (selectedOption) {
                selectedOption.selected = false;
            }
            el.selectedIndex = 0;
            this.setElementValue(el, el.name as CriteriaKeys, '');
        });
        this.form.querySelectorAll<HTMLInputElement>('input:not([type="checkbox"])').forEach((el) => {
            el.value = '';
        });
        this.form.querySelectorAll<HTMLInputElement>('input[type="checkbox"]').forEach((el) => {
            el.checked = false;
        });
        document.querySelector<HTMLInputElement>(`select[name="model"]`)!.disabled = true;
        document.querySelector<HTMLInputElement>(`select[name="model"]`)!.innerHTML = '';
        document.querySelector<HTMLInputElement>(`select[name="generation"]`)!.disabled = true;
        document.querySelector<HTMLInputElement>(`select[name="generation"]`)!.innerHTML = '';
        document.querySelector<HTMLInputElement>(`select[name="city"]`)!.disabled = true;
        document.querySelector<HTMLInputElement>(`select[name="city"]`)!.innerHTML = '';

        document.querySelector<HTMLInputElement>(this.selectedModelEl)!.value = '';
        document.querySelector<HTMLInputElement>(this.selectedGenerationEl)!.value = '';
        document.querySelector<HTMLInputElement>(this.selectedCityEl)!.value = '';
    }

    setElementValue(el: HTMLInputElement | HTMLSelectElement, name: CriteriaKeys, value: CriteriaValues) {
        switch (true) {
            case CHECKBOX_FIELDS.includes(name):
                this.fillCheckboxes(name, value as string[]);
                break;
            case ['make', 'model', 'region'].includes(name):
                el.value = value ? value.toString() : '';
                el.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
                break;
            default:
                el.value = value ? value.toString() : '';
        }
    }

    fillCheckboxes(name: string, value: boolean | string[]) {
        if (Array.isArray(value)) {
            console.log(name, value);
            value.forEach((checkboxValue) => {
                this.form
                    .querySelectorAll<HTMLInputElement>(`[name="${name}"][value="${checkboxValue}"]`)
                    .forEach((el) => {
                        el.checked = true;
                    });
            });
        } else {
            this.form.querySelector<HTMLInputElement>(`[name="${name}"]`)!.checked = value;
        }
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}

@customElement('x-search-hidden-counter')
export class HiddenInputCounterElement extends LitElement {
    @property()
    selector: string = '';

    @property()
    one: string = '';

    @property()
    few: string = '';

    @property()
    many: string = '';

    @state()
    count: number = 0;

    protected override firstUpdated() {
        const bindEvents = () => {
            document.querySelectorAll(`${this.selector} input, ${this.selector} select`).forEach((el) => {
                el.removeEventListener('input', this.recalculate.bind(this));
                el.addEventListener('input', this.recalculate.bind(this));
            });
        };

        document.addEventListener('htmx:afterSwap', () => bindEvents());
        bindEvents();
    }

    protected recalculate() {
        this.count = 0;
        this.count += document.querySelectorAll('[name="body_type"]:checked').length ? 1 : 0;
        this.count += document.querySelectorAll('[name="drive_type"]:checked').length ? 1 : 0;
        this.count += document.querySelectorAll('[name="engine_type"]:checked').length ? 1 : 0;
        this.count += document.querySelectorAll('[name="transmission"]:checked').length ? 1 : 0;
        this.count += document.querySelectorAll('[name="car_condition"]:checked').length ? 1 : 0;
        this.count += document.querySelectorAll('[name="carrier_code"]:checked').length ? 1 : 0;
        this.count += document.querySelectorAll('[name="source_name"]:checked').length ? 1 : 0;
        if (document.querySelector<HTMLInputElement>('[name="color"]')?.value != '') {
            this.count += 1;
        }
        if (document.querySelector<HTMLInputElement>('[name="region"]')?.value != '') {
            this.count += 1;
        }
        if (document.querySelector<HTMLInputElement>('[name="city"]')?.value != '') {
            this.count += 1;
        }
        if (document.querySelector<HTMLInputElement>('[name="exclude_text"]')?.value != '') {
            this.count += 1;
        }
        if (document.querySelector<HTMLInputElement>('[name="include_text"]')?.value != '') {
            this.count += 1;
        }
        if (document.querySelector<HTMLInputElement>('[name="source_name"]')?.value != '') {
            this.count += 1;
        }
    }

    protected override render(): unknown {
        if (!this.count) return '';
        const text = plural([this.one, this.few, this.many], this.count);
        return html`<span class="hidden-options">${text}</span>`;
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
