import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

// @ts-ignore
import diff from 'node-diff';

declare global {
    interface HTMLElementTagNameMap {
        'x-text-diff': TextDiffElement;
    }
}

@customElement('x-text-diff')
export class TextDiffElement extends LitElement {
    protected override render(): unknown {
        const leftText = this.querySelector<HTMLTemplateElement>('[data-left]')!.content.textContent;
        const rightText = this.querySelector<HTMLTemplateElement>('[data-right]')!.content.textContent;
        return html` <div>${unsafeHTML(diff(leftText, rightText))}</div>`;
    }

    protected override createRenderRoot(): Element | ShadowRoot {
        return this;
    }
}
