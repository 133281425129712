export const onlyDeleted = () => {
    let only_deleted = document.querySelector(`input[name="only_deleted"]`);
    let with_deleted = document.querySelector(`input[name="with_deleted"]`);
    let order = document.getElementById(`id_order`);
    let period = document.getElementById(`id_period`);
    let i;
    let j;
    for (i = 0; i < order.length; i++) {
        if (order.options[i].value === 'created_at:desc') {
            order.options[i].text = 'по дате удаления (последние)'
        } else if (order.options[i].value === 'created_at:asc') {
            order.options[i].text = 'по дате удаления (первые)'
        }
    }
    for (j = 0; j < period.length; j++) {
        if (period.options[j].value === '36500') {
            period.options[j].text = 'за год'
        }
    }

    if (only_deleted.checked) {
        with_deleted.checked = false;
    }
    else {
        for (i = 0; i < order.length; i++) {
            if (order.options[i].value === 'created_at:desc') {
                order.options[i].text = 'по дате (новые)'
            } else if (order.options[i].value === 'created_at:asc') {
                order.options[i].text = 'по дате (старые)'
            }
        }
        for (j = 0; j < period.length; j++) {
            if (period.options[j].value === '36500') {
                period.options[j].text = 'за всё время'
            }
        }
    }
};

export const withDeleted = () => {
    let only_deleted = document.querySelector(`input[name="only_deleted"]`);
    let with_deleted = document.querySelector(`input[name="with_deleted"]`);
    let order = document.getElementById(`id_order`);
    let period = document.getElementById(`id_period`);
    let i;
    let j;
    for (i = 0; i < order.length; i++) {
        if (order.options[i].value === 'created_at:desc') {
            order.options[i].text = 'по дате (новые)'
        } else if (order.options[i].value === 'created_at:asc') {
            order.options[i].text = 'по дате (старые)'
        }
    }
    for (j = 0; j < period.length; j++) {
        if (period.options[j].value === '36500') {
            period.options[j].text = 'за всё время'
        }
    }

    if (with_deleted.checked) {
        only_deleted.checked = false;
    }
};

export const onlyDeletedCheckedFalse = () => {
    let only_deleted = document.querySelector(`input[name="only_deleted"]`);
    let with_deleted = document.querySelector(`input[name="with_deleted"]`);
    if (only_deleted.checked) {
        with_deleted.checked = false;
        only_deleted.checked = false;
    }
};

export const withDeletedCheckedFalse = () => {
    let only_deleted = document.querySelector(`input[name="only_deleted"]`);
    let with_deleted = document.querySelector(`input[name="with_deleted"]`);
    if (with_deleted.checked) {
        only_deleted.checked = false;
        with_deleted.checked = false;
    }
};

export const allowDuplicatesChecked = () => {
    let allow_duplicates = document.querySelector(`input[name="allow_duplicates"]`);
    let allow_new_ads = document.querySelector(`input[name="allow_new_ads"]`);
    if (allow_duplicates.checked) {
        allow_new_ads.checked = true;
    }
};

export const allowNewAdsChecked = () => {
    let allow_duplicates = document.querySelector(`input[name="allow_duplicates"]`);
    let allow_new_ads = document.querySelector(`input[name="allow_new_ads"]`);
    if (allow_new_ads.checked == false) {
        allow_duplicates.checked = false;
    }
};

export const onlyCompany = () => {
    let only_company = document.querySelector(`input[name="only_company"]`);
    let only_private = document.querySelector(`input[name="only_private"]`);
    if (only_company.checked) {
        only_private.checked = false;
    }
};

export const onlyPrivate = () => {
    let only_company = document.querySelector(`input[name="only_company"]`);
    let only_private = document.querySelector(`input[name="only_private"]`);
    if (only_private.checked) {
        only_company.checked = false;
    }
};
