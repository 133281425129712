import { openPremiumRequiredAlert, openRegistrationRequiredAlert, openCountsRequiredAlert, openPremiumRequiredRemovedAlert } from './alerts';
import { withDeleted, onlyDeleted, withDeletedCheckedFalse, onlyDeletedCheckedFalse, onlyCompany, onlyPrivate, allowNewAdsChecked, allowDuplicatesChecked } from './buttons'
import 'notyf/notyf.min.css';
import { search, setViewPreference } from './search';
import { setPreferredCurrency } from './currencies';
import { closeCurrentModal } from './components';
import * as Sentry from '@sentry/browser';

export * from './components';
export * from './toasts';

const autobot = {
    search: { setViewPreference, search },
    preferences: { setPreferredCurrency },
    alerts: { openRegistrationRequiredAlert, openPremiumRequiredAlert, openPremiumRequiredRemovedAlert, openCountsRequiredAlert },
    buttons:{ withDeleted, onlyDeleted, withDeletedCheckedFalse, onlyDeletedCheckedFalse, onlyCompany, onlyPrivate, allowNewAdsChecked, allowDuplicatesChecked },
    modals: { closeCurrentModal, close: closeCurrentModal },
};

window.autobot = autobot;

declare global {
    interface Window {
        autobot: typeof autobot;
        __SETTINGS__: {
            sentryDSN: string;
            releaseID: string;
            environment: string;
        };
    }
}

if (window.__SETTINGS__.sentryDSN) {
    Sentry.init({
        dsn: window.__SETTINGS__.sentryDSN,
        release: window.__SETTINGS__.releaseID,
        environment: window.__SETTINGS__.environment,
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ['localhost', 'localhost:8000', 'https://autobot.by', 'https://autobot.pl'],
            }),
            new Sentry.Replay(),
        ],
    });
}

window.onpageshow = function (event) {
    if (event.persisted && navigator.userAgent.toLowerCase().includes('iphone')) {
        window.location.reload();
    }
};
