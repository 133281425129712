import { LitElement, PropertyValues } from 'lit';

import { customElement } from 'lit/decorators/custom-element.js';

export const ModalEvents = { INSERT: 'modals-insert', CLOSE: 'modals-close' };

declare global {
    interface HTMLElementTagNameMap {
        'x-modals': Modals;
    }
}

@customElement('x-modals')
export class Modals extends LitElement {
    protected override firstUpdated(_changedProperties: PropertyValues) {
        document.addEventListener(ModalEvents.CLOSE, () => this.onModalClose());
    }

    protected onModalClose() {
        const modals = this.querySelectorAll('*');
        modals.forEach((el) => el.remove());
    }

    protected override createRenderRoot = (): Element | ShadowRoot => this;
}

export const closeCurrentModal = () => {
    document.dispatchEvent(new CustomEvent(ModalEvents.CLOSE, { bubbles: true }));
    const dialog = document.querySelector<HTMLDialogElement>('dialog[open]');
    if (dialog) {
        dialog.open = false;
    }
};
